<!--
* @Author: DY
* @Date: 2021年1月8日14:49:09
* @Description: '查看价格设置—滁州'
-->
<template>
    <div class="priceSettingSeeForm" v-loading="loading">
        <div class="basicInfo">
            <table>
                <thead>
                    <tr>
                        <th colspan="6" class="thStyle1">
                            <div class="periodSelection" v-if="isShowSelect && formData.price_mode == 1">
                                <h4>当前期次：</h4>
                                <el-select v-model="selectPeriodCpvid" placeholder="请选择期次" @change="selectPeriod">
                                    <template v-for="(item, index) in periodData">
                                        <el-option
                                            :key="index"
                                            :label="item.current_price_period"
                                            :value="item.cpvid"
                                        >
                                        </el-option>
                                    </template>
                                </el-select>
                            </div>
                            基础信息
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>合同编号</th>
                        <td colspan="2">
                            {{ formData.contract_number }}
                        </td>
                        <th>工程名称</th>
                        <td colspan="2">
                            {{ formData.engineering_name }}
                        </td>
                    </tr>
                    <tr>
                        <th>客户名称</th>
                        <td colspan="2">
                            {{ formData.client_name }}
                        </td>
                        <th>价格模式</th>
                        <td colspan="2">
                            <el-radio-group v-model="formData.price_mode" disabled>
                                <el-radio :label="0">
                                    企业固定价
                                </el-radio>
                                <el-radio :label="1">
                                    市场信息价
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <th>对账信息</th>
                        <td colspan="2">
                            <el-radio-group v-model="formData.check_cycle_type" disabled>
                                <el-radio :label="0">
                                    自然月
                                </el-radio>
                                <el-radio :label="1">
                                    每月
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.check_cycle_day"
                                        readonly
                                    ></el-input>
                                    日
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <th>税率</th>
                        <td colspan="2">
                            <el-radio-group v-model="formData.tax_rate" disabled>
                                <template v-for="(item, index) in taxRateData">
                                    <el-radio :key="index" :label="item.val">
                                        {{ item.name }}
                                    </el-radio>
                                </template>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <p style="margin-bottom:0.1rem;">
                                价格取整
                            </p>
                        </th>
                        <td>
                            <el-radio-group v-model="formData.price_value_type" disabled>
                                <el-radio :label="0">
                                    取整
                                </el-radio>
                                <el-radio :label="1">
                                    小数点后1位
                                </el-radio>
                                <el-radio :label="2">
                                    小数点后2位
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <td>
                            <el-radio-group v-model="formData.price_rounding_mode" disabled>
                                <el-radio :label="0">
                                    进一法
                                </el-radio>
                                <el-radio :label="1">
                                    四舍五入
                                </el-radio>
                                <el-radio :label="2">
                                    舍掉
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <th>润泵砂浆价格</th>
                        <td colspan="2">
                            <el-radio-group v-model="formData.mortar_price_type" disabled>
                                <el-radio :label="0">
                                    与产品同价
                                </el-radio>
                                <el-radio :label="1">
                                    与产品不同价
                                    <p class="pStyle2">
                                        <el-input
                                            class="inputStyle1"
                                            v-model="formData.mortar_price_percent"
                                            readonly
                                        ></el-input>
                                        %产品价
                                        <el-input
                                            class="inputStyle1"
                                            v-model="formData.mortar_price"
                                            readonly
                                        ></el-input>
                                        元/方
                                    </p>
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <th>承兑方式</th>
                        <td colspan="5">
                            <el-checkbox v-model="formData.is_bank_acceptance" class="checkboxStyle1" disabled>
                                <h4>银行承兑</h4>
                                <p class="pStyle2">
                                    6个月内加价
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.bank_acceptance_six_add_money"
                                        readonly
                                    ></el-input>
                                    元，
                                    超6个月加价
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.bank_acceptance_exceed_six_add_money"
                                        readonly
                                    ></el-input>
                                    元。
                                </p>
                            </el-checkbox>
                            <el-checkbox v-model="formData.is_trade_acceptance" class="checkboxStyle1" disabled>
                                商业承兑
                                <p class="pStyle2">
                                    6个月内加价
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.trade_acceptance_six_add_money"
                                        readonly
                                    ></el-input>
                                    元，
                                    超6个月加价
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.trade_acceptance_exceed_six_add_money"
                                        readonly
                                    ></el-input>
                                    元。
                                </p>
                            </el-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <template v-if="formData.price_mode != 0">
            <div class="informationPriceSetting">
                <table>
                    <thead>
                        <tr>
                            <th colspan="6">
                                信息价设置
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>地区信息价</th>
                            <td colspan="2">
                                {{ formData.info_price_regional_name }}
                            </td>
                            <th>是否跨区组合</th>
                            <td colspan="2">
                                <el-radio-group v-model="formData.is_trans_regional" disabled>
                                    <el-radio :label="true">
                                        是
                                    </el-radio>
                                    <el-radio :label="false">
                                        否
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <th>信息价取值</th>
                            <td colspan="2">
                                <el-radio-group v-model="formData.get_price_type" disabled>
                                    <el-radio :label="0">
                                        上期信息价
                                    </el-radio>
                                    <el-radio :label="1">
                                        当期信息价
                                    </el-radio>
                                </el-radio-group>
                            </td>
                            <th>合同下浮比例</th>
                            <td colspan="2">
                                <p class="pStyle1">
                                    <input
                                        type="text"
                                        v-model="formData.discount_rate"
                                        class="inputStyle2"
                                        readonly
                                    >%
                                </p>
                                <br>
                                <el-radio-group v-model="formData.discount_rate_type" v-show="false">
                                    <el-radio :label="0">
                                        按含税单价
                                    </el-radio>
                                    <el-radio :label="1">
                                        按除税单价
                                    </el-radio>
                                </el-radio-group>
                                <el-radio-group
                                    v-model="formData.pump_discounts_mode"
                                    disabled
                                >
                                    <el-radio :label="1">
                                        仅泵送优惠， 非泵送 -
                                        <el-input
                                            class="inputStyle1"
                                            v-model="formData.no_pump_minus_money"
                                            disabled
                                            type="number"
                                        ></el-input>
                                        元
                                    </el-radio>
                                    <el-radio :label="2">
                                        仅非泵送优惠，泵送 +
                                        <el-input
                                            class="inputStyle1"
                                            v-model="formData.pump_add_money"
                                            disabled
                                            type="number"
                                        ></el-input>
                                        元
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <th>按基准期次价<br>格浮动调整</th>
                            <td colspan="5">
                                <el-radio-group v-model="formData.is_base_price_adjusted" disabled>
                                    <el-radio :label="true">
                                        是
                                    </el-radio>
                                    <el-radio :label="false">
                                        否
                                    </el-radio>
                                </el-radio-group>
                                <p class="pStyle1" v-if="formData.is_base_price_adjusted">
                                    本期信息价浮动超出±
                                    <input
                                        type="text"
                                        readonly
                                        v-model="formData.price_adjustment_range"
                                        class="inputStyle2"
                                    >
                                    %时调整合同价格
                                </p>
                                <el-radio-group
                                    disabled
                                    style="padding-left: 1rem;"
                                    v-model="formData.adjustment_ratio_type"
                                    v-if="formData.is_base_price_adjusted"
                                >
                                    <el-radio :label="0">
                                        按超出比例
                                    </el-radio>
                                </el-radio-group>
                                <el-radio-group
                                    disabled
                                    style="padding-left: 1rem;"
                                    v-model="formData.adjustment_calculation_type"
                                    v-if="formData.is_base_price_adjusted"
                                >
                                    <el-radio :label="0">
                                        先浮动、后优惠
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr v-if="formData.is_base_price_adjusted">
                            <th>基准信息价期次</th>
                            <td colspan="2">
                                {{ formData.base_price_period }}
                            </td>
                            <th>基准产品型号</th>
                            <td colspan="2">
                                {{ formData.base_specification_mode_name }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <div class="marketInformationPrice">
            <table>
                <thead>
                    <tr>
                        <th colspan="10">
                            产品信息价格
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>价格模式</th>
                        <th>区域信息价</th>
                        <th>规格型号</th>
                        <th>市场价格</th>
                        <th>优惠比例(%)</th>
                        <th>优惠金额(元)</th>
                        <th>含税单价(元)</th>
                        <th>税率(%)</th>
                        <th>除税单价(元)</th>
                        <th>额外可用<br>附加型号</th>
                    </tr>
                    <template v-for="(item,index) in formData.ContractPriceMarkNumList">
                        <tr :key="index" :class="{ infoPriceBg: item.price_type == 1, enterprisePriceBg: item.price_type == 0 }">
                            <td>
                                <template v-if="item.price_type == 0">
                                    固定价
                                </template>
                                <template v-else>
                                    信息价
                                </template>
                            </td>
                            <td>{{ item.region_name }}</td>
                            <td>{{ item.specification_mode_name }}</td>
                            <td>{{ item.market_price }}</td>
                            <td>{{ item.discount_rate }}</td>
                            <td>{{ item.discount_money }}</td>
                            <td>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    placement="top"
                                    v-if="item.price_explain"
                                >
                                    <!-- eslint-disable-next-line vue/no-v-html -->
                                    <div slot="content" v-html="item.price_explain"></div>
                                    <span style="color: #409eff;">{{ item.included_tax_unit_price }}</span>
                                </el-tooltip>
                                <span style="color: #409eff;" v-else>
                                    {{ item.included_tax_unit_price }}
                                </span>
                            </td>
                            <td>{{ item.tax_rate }}</td>
                            <td>{{ item.after_tax_unit_price }}</td>
                            <td>
                                <template v-if="numMoreObjs[item.compose_code]">
                                    <span class="tag-more-text" @click="viewMoreTag(item)">······</span>
                                </template>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div class="additionalModels">
            <table>
                <thead>
                    <tr>
                        <th colspan="7">
                            附加型号设置
                        </th>
                    </tr>
                </thead>
                <template v-for="(item,index) in formData.ContractPriceAdditionalList">
                    <tbody :key="index">
                        <tr>
                            <th>附加型号</th>
                            <td>{{ item.additional_model }}</td>
                            <th>价格</th>
                            <td>{{ item.included_tax_unit_price }}</td>
                            <th>优惠后价格</th>
                            <td>{{ item.additional_model_price }}</td>
                            <td>
                                <el-checkbox-group v-model="item.checkAll" disabled>
                                    <el-checkbox label="所有标号"></el-checkbox>
                                </el-checkbox-group>
                            </td>
                        </tr>
                        <tr v-if="!item.checkAll">
                            <th>产品标号</th>
                            <td colspan="6">
                                <el-checkbox-group v-model="item.checkedProductNumList" disabled>
                                    <template v-for="(k, i) in item.productNumList">
                                        <el-checkbox
                                            :key="i"
                                            :label="k.compose_code"
                                        >
                                            {{ k.specification_mode_name }}
                                        </el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </td>
                        </tr>
                        <tr>
                            <th>是否优惠</th>
                            <td colspan="6">
                                <el-radio-group v-model="item.is_discount" disabled>
                                    <el-radio :label="true">
                                        是
                                    </el-radio>
                                    <el-radio :label="false">
                                        否
                                    </el-radio>
                                </el-radio-group>
                                <p class="pStyle1">
                                    优惠比例±
                                    <input
                                        type="text"
                                        v-model="item.discount_rate"
                                        readonly
                                        class="inputStyle2"
                                    >
                                    %
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </table>
        </div>
        <div class="pouringMethod">
            <table>
                <thead>
                    <tr>
                        <th colspan="4">
                            浇筑方式设置
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>基础泵送费(元/方)</th>
                        <td>{{ formData.info_price_minus_pump_money }} </td>
                        <th>地泵费(元/方)</th>
                        <td>{{ formData.ground_pump_price }}</td>
                    </tr>
                    <tr>
                        <th>自备泵(元/方)</th>
                        <td>{{ formData.self_owned_pump }}</td>
                        <th>吊斗(元/方)</th>
                        <td>{{ formData.cableway_bucket }}</td>
                    </tr>
                    <tr>
                        <th>泵送补充模式</th>
                        <td colspan="3">
                            <el-checkbox
                                v-model="formData.pumper_money_mode"
                                :true-label="1"
                                :false-label="0"
                                class="checkboxStyle1"
                                readonly
                                :disabled="true"
                            >
                                加收出场费
                                <p class="pStyle1">
                                    不足
                                    <el-input
                                        placeholder="请输入"
                                        class="inputStyle1"
                                        v-model="formData.pumper_volumn_min"
                                        type="number"
                                        readonly
                                    ></el-input>
                                    方，收出场费
                                    <el-input
                                        placeholder="请输入"
                                        class="inputStyle1"
                                        v-model="formData.pumper_add_money"
                                        type="number"
                                        readonly
                                    ></el-input>元
                                </p>
                            </el-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <th rowspan="2">
                            优惠项目
                        </th>
                        <td colspan="3">
                            <el-radio-group v-model="formData.pump_discounts_type" disabled>
                                <el-radio :label="0">
                                    每月减免
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.pump_month_derate"
                                        readonly
                                    ></el-input>
                                    次小方量泵送费（泵车进场费）
                                </el-radio>
                                <el-radio :label="1">
                                    按合同共减免
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.pump_all_derate"
                                        readonly
                                    ></el-input>
                                    次小方量泵送费（泵车进场费）
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <el-radio-group v-model="formData.special_pump_discounts_type" disabled>
                                <el-radio :label="0">
                                    每月减免
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.special_pump_month_derate"
                                        readonly
                                    ></el-input>
                                    次特殊泵种费
                                </el-radio>
                                <el-radio :label="1">
                                    按合同共减免
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.special_pump_all_derate"
                                        readonly
                                    ></el-input>
                                    次特殊泵种费
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div style="display: flex;">
                                <el-radio-group v-model="formData.is_pump_base" disabled>
                                    <el-radio :label="true">
                                        统一汽车泵价格
                                        <el-input
                                            class="inputStyle1"
                                            v-model="formData.pump_price"
                                            readonly
                                        ></el-input>
                                        元/m³
                                    </el-radio>
                                    <el-radio :label="false">
                                        泵送高度区间配置
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table v-if="!formData.is_pump_base">
                <tbody>
                    <tr>
                        <th>泵送高度(m)</th>
                        <th>泵送价格(元)</th>
                    </tr>
                    <tr v-for="pump in formData.ContractPricePumpList" :key="pump.cppiid">
                        <td class="pump-select" style="padding-left:0;">
                            <el-select v-model="pump.pump_lower" class="selectStyle1" disabled>
                                <el-option
                                    v-for="k in pumpingHeightSelectData"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                            <span class="spanStyle1">-</span>
                            <el-select v-model="pump.pump_upper" class="selectStyle1" disabled>
                                <el-option
                                    v-for="k in pumpingHeightSelectData"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td style="text-align:center;">
                            {{ pump.pump_price }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="transportationCost">
            <table>
                <thead>
                    <tr>
                        <th colspan="6">
                            运输费用设置
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>我方运输</th>
                        <td colspan="2">
                            <el-radio-group v-model="formData.is_self_carriage" disabled>
                                <el-radio :label="true">
                                    是
                                </el-radio>
                                <el-radio :label="false">
                                    否
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <th>是否含基础运费</th>
                        <td colspan="2">
                            <el-radio-group v-model="formData.is_included_freight" disabled>
                                <el-radio :label="true">
                                    是
                                </el-radio>
                                <el-radio :label="false">
                                    否
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <th>基础运费(元/m³)</th>
                        <td>{{ formData.base_freight }}</td>
                        <th>基础公里数(km)</th>
                        <td>{{ formData.base_mileage }}</td>
                        <th>超出运距费用(元/km/m³)</th>
                        <td>{{ formData.than_distance }}</td>
                    </tr>
                    <tr>
                        <th>运费补充模式</th>
                        <td colspan="5">
                            <el-radio-group v-model="formData.compensate_charge_way" disabled>
                                <el-radio :label="1">
                                    补方
                                    <p class="pStyle1">
                                        每车不足
                                        <el-input
                                            placeholder="请输入"
                                            v-model="formData.compensate_capacity"
                                            readonly
                                            type="number"
                                            @mousewheel.native.prevent
                                            class="inputStyle1"
                                        ></el-input> 方，按
                                        <el-input
                                            placeholder="请输入"
                                            v-model="formData.bv_volumn_add"
                                            readonly
                                            class="inputStyle1"
                                            type="number"
                                            @mousewheel.native.prevent
                                        ></el-input> 方收取运费。
                                    </p>
                                </el-radio>
                                <br>
                                <el-radio :label="2">
                                    补固定金额
                                    <p class="pStyle1">
                                        单次方不足
                                        <el-input
                                            placeholder="请输入"
                                            class="inputStyle1"
                                            v-model="formData.bp_volumn_less"
                                            readonly
                                            type="number"
                                            @mousewheel.native.prevent
                                        ></el-input>
                                        方,按实际方量价格结算后加收
                                        <el-input
                                            placeholder="请输入"
                                            class="inputStyle1"
                                            v-model="formData.plus_money"
                                            readonly
                                            type="number"
                                            @mousewheel.native.prevent
                                        ></el-input> 元。
                                        <el-switch
                                            disabled
                                            v-model="formData.bp_volumn_is_no_frist"
                                            active-text="首次不收费，二次正常收费"
                                        >
                                        </el-switch>
                                    </p>
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <th>对方自运优惠(元/m³)</th>
                        <td colspan="2">
                            {{ formData.transport_price }}
                        </td>
                        <th>拉水(元/车)</th>
                        <td colspan="2">
                            {{ formData.pull_water }}
                        </td>
                    </tr>
                    <tr>
                        <th>优惠项目</th>
                        <td colspan="5">
                            <el-radio-group v-model="formData.freight_discounts_type" disabled>
                                <el-radio :label="0">
                                    每月减免
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.freight_month_derate"
                                        readonly
                                    ></el-input>
                                    次小方量运费
                                </el-radio>
                                <el-radio :label="1">
                                    按合同共减免
                                    <el-input
                                        class="inputStyle1"
                                        v-model="formData.freight_all_derate"
                                        readonly
                                    ></el-input>
                                    次小方量运费
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer v-if="isToExamine">
            <el-button type="primary" @click="confirmationAudit = true">
                审核完成
            </el-button>
            <el-button @click="cancel">
                取消
            </el-button>
        </footer>
        <el-dialog
            width="40%"
            :modal="false"
            title="确认审核"
            :visible.sync="confirmationAudit"
            custom-class="confirmationAuditClass"
        >
            <h3>备注：</h3>
            <el-input :rows="5" type="textarea" v-model="reviewNotes"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmationAudit = false">取 消</el-button>
                <el-button type="primary" @click="contractPriceApproval">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="30%"
            height="30%"
            :modal="false"
            center
        >
            <ul>
                <li
                    class="tag el_left"
                    v-for="(tag,i) in currentNumMore"
                    :key="i"
                >
                    <span class="tag-text" :title="tag">{{ tag }}</span>
                </li>
            </ul>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'price-setting-see-form',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            // 是否显示选择期次
            isShowSelect: true,
            // 期数选择年
            selectYear: '',
            // 期次数据
            periodData: [],
            // 当前选择期次编码
            selectPeriodCpvid: '',
            // 税率
            taxRateData: [{
                name: '3%',
                val: 3,
            }, {
                name: '6%',
                val: 6,
            }, {
                name: '13%',
                val: 13,
            }],
            // 表单数据
            formData: {
                ContractPriceMarkNumList: [],
                ContractPriceAdditionalList: [],
                ContractPriceAdditionalMarkList: [],
                ContractPricePumpList: [],
            },
            // 泵送字典数据列表
            pumpingHeightSelectData: [],
            IsRead: false,
            // 更多附加型号弹框显示隐藏
            dialogVisible: false,
            // 当前要弹出更多型号的标号数据
            currentNumMore: [],
            // 更多型号的标号数据 归类
            numMoreObjs: {},
            // 更多附加型号标题
            dialogTitle: '',
            // 是否是审核
            isToExamine: false,
            // 确认审核弹出
            confirmationAudit: false,
            // 确认审核备注
            reviewNotes: '',
        };
    },
    watch: {
        selectPeriodCpvid() {
            this.getContractPrice();
        },
    },
    computed: {},
    created() {
        // 默认当前年
        this.selectYear = new Date().getFullYear();
        if (this.extr) {
            if (this.extr.isNotShowSelect) {
                this.isShowSelect = false;
            }
            // 获取期次数据
            if (this.isShowSelect && this.extr.contract_number) {
                this.loading = true;
                this.getPeriodData();
            }
            if (this.extr.cpvid) {
                this.selectPeriodCpvid = this.extr.cpvid;
            }
            // 是否是审核
            if (this.extr.isToExamine) {
                this.isToExamine = true;
            }
        }
    },
    mounted() {},
    methods: {
        // 获取期次数据
        getPeriodData() {
            this.$axios
                .get(`/interfaceApi/sale/contract_price/info_price_period?contract_number=${this.extr.contract_number}`)
                .then(res => {
                    if (res && res.length > 0) {
                        this.periodData = res;
                        if (!this.extr.cpvid) {
                            this.selectPeriodCpvid = res[0].cpvid;
                        }
                    } else {
                        this.loading = false;
                    }
                }).catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择期次
        selectPeriod(code) {
            this.selectPeriodCpvid = code;
        },

        // 获取价格表
        getContractPrice() {
            this.$axios
                .get(`/interfaceApi/sale/contract_price/info_price_version_by_id/${this.selectPeriodCpvid}`)
                .then(res => {
                    if (res) {
                        this.formData = res;
                        // 初始化处理数据
                        this.analysisRelationship();
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 初始化数据
        analysisRelationship() {
            // 初始化判断是否有关联关系
            if (this.formData.ContractPriceAdditionalMarkList.length > 0) {
                // 初始化 附加型号根据关联关系，绑定关联的信息价
                this.formData.ContractPriceAdditionalList.forEach(z => {
                    // 自定义-选中的标号集合
                    z.checkedProductNumList = [];
                    // 自定义-标号集合
                    z.productNumList = this.formData.ContractPriceMarkNumList;
                    this.formData.ContractPriceAdditionalMarkList.forEach(item => {
                        if (item.additional_model_code === z.additional_model_code) {
                            z.checkedProductNumList.push(item.compose_code);
                        }
                    });
                    // 自定义-是否是所有型号
                    if (z.checkedProductNumList.length === z.productNumList.length) {
                        z.checkAll = true;
                    } else {
                        z.checkAll = false;
                    }
                });
            } else {
                this.formData.ContractPriceAdditionalList.forEach(z => {
                    // 自定义-是否是所有型号
                    z.checkAll = false;
                    // 自定义-选中的标号集合
                    z.checkedProductNumList = [];
                    // 自定义-标号集合
                    z.productNumList = this.formData.ContractPriceMarkNumList;
                });
            }
            if (this.formData.ContractPriceMarkNumList.length > 0) {
                this.formData.ContractPriceMarkNumList.forEach(item => {
                    if (item.additional_model) {
                        item.additionalModels = item.additional_model.split('+');
                    }
                });
            }
            // 格式化附加型号和产品
            const numMoreObjs = this.numMoreObjs;
            if (Object.keys(numMoreObjs).length === 0) {
                this.formData.ContractPriceAdditionalMarkList.forEach(item => {
                    if (numMoreObjs[item.compose_code]) {
                        numMoreObjs[item.compose_code].push(item.additional_model);
                    } else {
                        numMoreObjs[item.compose_code] = [item.additional_model];
                    }
                });
            }
        },

        // 查看更多附加型号
        viewMoreTag(data) {
            this.dialogTitle = data.product_mark_num + '-附加型号';
            this.currentNumMore = this.numMoreObjs[data.compose_code];
            this.dialogVisible = true;
        },
        // 合同价格审批
        contractPriceApproval() {
            this.loading = true;
            this.$axios
                .put('/interfaceApi/sale/contract_price/contract_price_approval', {
                    contract_adjusted_id: this.extr.cpia_id,
                    contract_price_versions_id: this.extr.cpvid,
                    approval_remark: this.reviewNotes,
                    status: 2,
                })
                .then(() => {
                    this.$parent.hide();
                    this.extr.table.getTableList();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 取消
        cancel() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus">
.priceSettingSeeForm
    height 100%
    overflow-y auto
    padding 0.14rem
    position relative
    >div
        margin-bottom 0.2rem
        table
            width 100%
            border-collapse collapse
            thead
                th
                    height 0.44rem
                    font-size 0.18rem
                    font-weight 600
            th,td
                height 0.44rem
                font-size 0.16rem
                border 1px solid #D7D7D7
                font-weight 400
                width 1.5rem
            th
                background #EDF0F5
                color #022782
                padding 0 0.05rem
                &.thStyle1
                    position relative
                    .periodSelection
                        position absolute
                        left 0.1rem
                        top 0.03rem
                        font-size 0.18rem
                        display flex
                        font-weight 400
                        h4
                            color #022782
                            line-height 0.36rem
            td
                padding-left 0.2rem
                /*width: 299px;*/
                .el-checkbox__label
                      color #303030!important
                .el-input
                    .el-input__inner
                        height 0.43rem
                        line-height 0.43rem
                .el-tag
                    margin 0.05rem
                .inputStyle1
                    width 0.8rem
                    .el-input__inner
                        height 0.32rem
                        line-height 0.3rem
                .inputStyle2
                    width 0.6rem
                    line-height 0.2rem
                    border none
                    border-bottom 1px solid #D7D7D7
                    text-align center
                    margin 0 0.05rem
                .el-radio-group
                    .el-radio
                        margin-top 0.05rem
                        margin-bottom 0.05rem
                        .el-radio__label
                            line-height 0.24rem
                        &.is-disabled
                            .el-radio__label
                                color #606266
                        &.is-checked
                            .el-radio__label
                                color #409EFF
                            .el-radio__inner
                                border-color #409EFF
                                background #409EFF
                .pStyle1
                    display inline-block
                    margin-left 0.2rem
                .pStyle2
                    margin-left 0.3rem
                .pStyle3
                    margin 0 0.05rem 0.05rem
                    white-space nowrap
                .pStyle4
                    margin-left 0.2rem
                    margin-bottom 0.05rem
                .spanStyle1
                    display inline-block
                    width 0.5rem
                    text-align center
                .el-select
                    width 100%
                .selectStyle1
                    width calc((100% - 0.5rem)/2)
                &.tdStyle1
                    text-align center
    .transportationCost
        margin-bottom 0
    .marketInformationPrice
        table
            text-align center
            td
                padding-left 0
        .infoPriceBg
            background #F5F2ED
        .enterprisePriceBg
            background #E9F3F7
    .el-dialog__header
        background #4f67ad
        color #fff
        padding 10px 20px 10px
        .el-dialog__title
            color #fff
        .el-dialog__headerbtn
            top 10px
            color #fff
            font-size 18px
            .el-dialog__close
                color #fff
    .el-dialog
        height 300px
        .el-dialog__body
            height calc(100% - 44px)
            overflow-y auto
            overflow-x hidden
    .tag
        width 1.5rem
        border 1px solid #EAEAEA
        height .32rem
        line-height .32rem
        margin-left .1rem
        color #333
        cursor pointer
        background #F7F7F7
        margin-bottom .1rem
        margin-top: .1rem;
        .tag-text
            float left
            width calc(100% - .24rem)
            white-space nowrap
            text-overflow ellipsis
            overflow hidden
            padding 0 .05rem
        .iconguanbi
            font-size .12rem
            color #979EA7
            float right
            margin-right .1rem
            width .14rem
        &:hover
            background #FEF8F8
            border 1px solid #D6000F
            color #D6000F
            .iconguanbi
                color #D6000F
    footer
        width 100%
        position sticky
        bottom -0.14rem
        left 0
        text-align center
        background #fff
        .el-button
            width 2.4rem
            font-size 0.16rem
            line-height 0.48rem
            margin 0.2rem
            padding 0
    .confirmationAuditClass
        .el-dialog__body
            padding 0.1rem 0.2rem
            height calc(100% - 110px)
            h3
                width 0.8rem
                font-size 0.18rem
                line-height 0.36rem
                margin-bottom 0.05rem
            .el-textarea
                height calc(100% - 0.41rem)
                .el-textarea__inner
                    height 100%
                    resize none
</style>
